import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.scss']
})
export class ExamListComponent implements OnInit {

  get feature() { return this.cs.getFeature()};
  
  constructor(public cs:ClientInfoService) { }

  ngOnInit(): void {
  }

}
