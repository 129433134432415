
<div *ngIf= '!this.siteObj' class="px-5 py-5 text-center text-info">
    <img src="/assets/img/loader4_new.gif">
   <!--
    Loading ...
    {{cs.site}}-{{cs.cat}}
    <img src="/assets/img/loader4_new.gif">
    -->

</div>
